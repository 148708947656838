<template>
    <div class="Profile-awards">
        <ul class="nav nav-tabs awards-tabs">
            <li class="nav-item">
                <a class="nav-link awards-tabs__title" 
                    @click.prevent="setActive('all-awards')" 
                    :class="{ active: isActive('all-awards') }" href="#all-awards">
                    {{$t("profile.awards.all-tab")}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link awards-tabs__title" 
                    @click.prevent="setActive('my-awards')" 
                    :class="{ active: isActive('my-awards') }" href="#my-awards">
                    {{$t("profile.awards.my-tab")}}
                </a>
            </li>
        </ul>

        <div class="tab-content test-tab-content">
            <div class="tab-pane fade" :class="{ 'active show': isActive('all-awards') }" id="all-awards">
                <div class="awards__rows">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-1.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.feedback-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.feedback-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-2.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.feedback-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.feedback-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-3.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.feedback-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.feedback-desc")}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-1.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.feedback-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.feedback-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-2.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.feedback-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.feedback-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-3.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.feedback-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.feedback-desc")}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b-pagination
                    v-model="currentPageAllAwards"
                    :total-rows="rowsAllAwards"
                    :per-page="perPageAllAwards"
                    last-number
                    first-number
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
                <!-- <div class="awards__rows" v-show="showMoreAllAwards">
                    <div class="awards__rows">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-1.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.feedback-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.feedback-desc")}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-2.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.feedback-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.feedback-desc")}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-3.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.feedback-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.feedback-desc")}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="text-center">
                    <button class="awards__more" @click="showMoreAllAwards =! showMoreAllAwards">
                        <span v-if="showMoreAllAwards">{{$t('profile.awards.less-btn')}}</span>
                        <span v-else>{{$t('profile.awards.more-btn')}}</span>
                    </button>
                </div> -->
            </div>

            <div class="tab-pane fade" :class="{ 'active show': isActive('my-awards') }" id="my-awards">
                <div class="awards__rows">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-4.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.place-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.place-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-4.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.place-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.place-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-4.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.place-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.place-desc")}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-4.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.place-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.place-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-4.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.place-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.place-desc")}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                            <div class="awards-card">
                                <img src="/images/profile/awards-4.svg" alt="">
                                <div class="awards-card__title">
                                    {{$t("profile.awards.place-title")}}
                                </div>
                                <div class="awards-card__desc">
                                    {{$t("profile.awards.place-desc")}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b-pagination
                    v-model="currentPageMyAwards"
                    :total-rows="rowsMyAwards"
                    :per-page="perPageMyAwards"
                    last-number
                    first-number
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
                <!-- <div class="awards__rows" v-show="showMoreMyAwards">
                    <div class="awards__rows">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-4.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.place-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.place-desc")}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-4.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.place-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.place-desc")}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-4.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.place-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.place-desc")}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-4.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.place-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.place-desc")}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-4.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.place-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.place-desc")}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 awards__card">
                                <div class="awards-card">
                                    <img src="/images/profile/awards-4.svg">
                                    <div class="awards-card__title">
                                        {{$t("profile.awards.place-title")}}
                                    </div>
                                    <div class="awards-card__desc">
                                        {{$t("profile.awards.place-desc")}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="text-center">
                    <button class="awards__more" @click="showMoreMyAwards =! showMoreMyAwards">
                        <span v-if="showMoreMyAwards">{{$t('profile.awards.less-btn')}}</span>
                        <span v-else>{{$t('profile.awards.more-btn')}}</span>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                activeItem: 'all-awards',
                currentPageMyAwards: 1,
                rowsMyAwards: 10,
                perPageMyAwards: 1,
                currentPageAllAwards: 1,
                rowsAllAwards: 10,
                perPageAllAwards: 1,
                // showMoreAllAwards: false,
                // showMoreMyAwards: false,
            }
        },
        beforeCreate() {
        },
        methods: {
            isActive (menuItem) {
                return this.activeItem === menuItem
            },
            setActive (menuItem) {
                this.activeItem = menuItem
            },
        },
    }
</script>
<style lang="less" scoped>
.Profile-awards {
    max-width: 100%;
    // margin: 0 auto;
}
.awards-tabs {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 75px;
}
.nav-tabs.awards-tabs {
    border-bottom: none;
}
.awards-tabs__title {
    width: 218px;
    text-align: center;
    border: 1px solid #473F95;
    box-sizing: border-box;
    padding: 8px 24px;
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.awards-tabs__title.active {
    background: #473F95;
    border: 1px solid #473F95;
    color: #FFFFFF;
}
.awards-tabs__title:hover {
    border: 1px solid #473F95;
}
.awards-tabs .nav-item:first-child .awards-tabs__title {
    border-radius: 5px 0 0 5px;
} 

.awards-tabs .nav-item:last-child .awards-tabs__title {
    border-radius: 0 5px 5px 0;
} 
.awards-card {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}
.awards-card__title {
    color: #473F95;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    padding: 24px 0;
}
.awards-card__desc {
    color: #000000;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.awards__rows .row:last-child {
    margin-top: 30px;
    margin-bottom: 30px;
}
.awards__more {
    color: #C4C4C4;
    text-decoration-line: underline;
    font-size: 20px;
    line-height: 23px;
    border: none;
    background-color: transparent;
    margin-top: 6px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.awards__card {
    position: relative;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 25px;
    padding-top: 15px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
@media (max-width: 991px) {
   .awards-tabs__title {
       width: auto;
       padding: 14px;
       
   }
   .awards-tabs .nav-item:first-child .awards-tabs__title {
       margin-right: 12px;
       border-radius: 12px;   
   }
   .awards-tabs .nav-item:last-child .awards-tabs__title {
        border-radius: 12px;    
   }
   .awards-tabs {
       margin-bottom: 30px;
   }
    /deep/ .pagination {
       padding-top: 0;
       margin-bottom: 0;
   }
    /deep/ .pagination .page-item .page-link {
            font-size: 14px;
            line-height: 16px;
            min-width: 38px;
    }
}
@media (max-width: 767px) {
    .awards-card {
        margin: 0 auto;
   }
   .awards__card {
       margin-bottom: 30px;
   }
   .awards__rows .row:last-child {
       margin-top: 0;
   }
}
</style>